import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/86SZUwt0mK4"
    bibleGroupSrc="https://www.docdroid.net/JNYrZID/bible-group-homework-5-31-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/16082587"
  >
    <SEO title="The Cosmic Christ - Colossians" />
  </Layout>
)

export default SermonPost
